<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
  >
    <v-btn
      slot="activator"
      color="primary"
      dark
      fixed
      bottom
      right
      fab
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-card>
        <v-card-title>
          <span class="headline">New Course Schedule</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-autocomplete
                  v-model="newSchedule.courseID"
                  :items="coursesWithDisplayName"
                  item-text="displayName"
                  item-value="id"
                  label="Course"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-select
                  v-model="newSchedule.facultyID"
                  :items="facultiesWithFullName"
                  item-text="fullName"
                  item-value="id"
                  label="Faculty"
                />
              </v-flex>
              <v-flex md2 />
              <v-flex
                xs12
                sm6
                md4
              >
                <v-select
                  v-model="newSchedule.termID"
                  :items="termsWithDisplayName"
                  label="School Term"
                  item-text="displayName"
                  item-value="termID"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="newSchedule.startingDate"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="newSchedule.startingDate"
                    label="Starting Date"
                    prepend-icon="event"
                    required
                  />
                  <v-date-picker
                    v-model="newSchedule.startingDate"
                    @input="$refs.menu1.save(newSchedule.startingDate)"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="newSchedule.endingDate"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="newSchedule.endingDate"
                    label="Ending Date"
                    prepend-icon="event"
                    required
                  />
                  <v-date-picker
                    v-model="newSchedule.endingDate"
                    @input="$refs.menu2.save(newSchedule.endingDate)"
                  />
                </v-menu>
              </v-flex>
              <!-- <v-flex md4></v-flex>
          <v-flex xs12 sm6 md2>
            <v-select
              v-model="newSchedule.semester"
              :items="semesters"
              label="Semester"
            ></v-select>
          </v-flex> -->
              <v-flex
                xs12
                sm6
                md4
              >
                <v-select
                  v-model="newSchedule.offer"
                  :items="offers"
                  label="Offer"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-select
                  v-model="newSchedule.campus"
                  :items="campus"
                  label="Campus"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md2
              >
                <v-select
                  v-model="newScheduleTimes.roomNo"
                  :items="rooms"
                  label="Room"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-select
                  v-model="newScheduleTimes.startAt"
                  :items="timePeriods"
                  :min="selectedTermStartDate"
                  label="Start At"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-select
                  v-model="newScheduleTimes.duration"
                  :items="durations"
                  label="Duration"
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
              >
                <v-select
                  v-model="newScheduleTimes.days"
                  :items="days"
                  :menu-props="{ maxHeight: '400' }"
                  label="Days"
                  chips
                  multiple
                  persistent-hint
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            flat
            @click.native="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            flat
            @click.native="submit()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'ClassNewDialogButton',
  data() {
    return {
      dialog: false,
      valid: true,
      menu1: false,
      menu2: false,
      courses: [],
      faculties: [],
      terms: [],
      offers: ['Full Time', 'Part Time', 'Full/Part', 'Online Course'],
      rooms: ['A', 'B', 'C'],
      campus: ['New York', 'Mississauga', 'Online'],
      durations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      timePeriods: [
        '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00',
      ],
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      newSchedule: {
        psID: '',
        courseID: '',
        termID: '',
        startingDate: '',
        endingDate: '',
        offer: '',
        campus: '',
        facultyID: '',
      },
      newScheduleTimes: {
        days: [], startAt: '', duration: '', roomNo: '',
      },
    }
  },
  computed: {
    facultiesWithFullName() {
      return this.faculties.map(faculty => Object.assign({}, faculty, { fullName: `${faculty.firstName} ${faculty.lastName}` }))
    },
    coursesWithDisplayName() {
      return this.courses.map(course => Object.assign({}, course, { displayName: `(${course.id}) ${course.name}` }))
    },
    termsWithDisplayName() {
      return this.terms.map(term => Object.assign({}, term, { displayName: `${term.academicYear} ${term.semester} ${term.name}` }))
    },
    revisedScheduleTimes() {
      const times = []
      const {
        days, startAt, duration, roomNo,
      } = this.newScheduleTimes
      days.forEach((day) => {
        times.push({
          day,
          time: startAt,
          duration,
          roomNo,
        })
      })
      return times
    },
    selectedTermStartDate() {
      const selectedTerm = this.terms.find(term => term.termID === this.newSchedule.termID)
      return selectedTerm ? selectedTerm.startDate : '0000-00-00'
    },
  },
  created() {
    this.$store.dispatch('courses/all')
      .then((data) => { this.courses = data })

    this.$store.dispatch('users/allEnrolledFaculty')
      .then((data) => { this.faculties = data })

    this.$store.dispatch('terms/all')
      .then((data) => { this.terms = data })
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('courseschedules/create', {
          schedule: JSON.stringify(this.newSchedule),
          timings: JSON.stringify(this.revisedScheduleTimes),
        })
          .then((data) => {
            this.clear()
            this.dialog = false
            this.$emit('newScheduleAdded', data)
          })
      }
    },
    clear() {
      this.$refs.form.reset()
    },
  },
}
</script>
