<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <!-- <v-flex xs12>
        <v-btn @click="conductDeduction">Run Fee Deduction</v-btn>
      </v-flex> -->
      <h3>Current Running</h3>
      <v-flex xs12>
        <v-divider />
      </v-flex>
      <v-flex
        v-for="schedule in ongoingSchedules"
        :key="schedule.csID"
        xs12
        sm6
        md3
        @click="toSchedule(schedule.csID)"
      >
        <v-card hover>
          <v-card-title>
            <h3>
              {{ `${schedule.courseID} ${schedule.classID}` }}
            </h3>
          </v-card-title>
          <v-card-text>
            {{ `Period: ${schedule.startingDate} ~ ${schedule.endingDate}` }}
            <v-spacer />
            {{ `Teacher: ${schedule.facultyID}` }}
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn
              dark
              color="indigo"
              small
              @click="toSchedule(schedule.csID)"
            >
              Go To
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex>
        <class-new-button @newScheduleAdded="addSchedule" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ClassNewDialogButton from '@/components/course-schedule/ClassNewDialogButton.vue'

export default {
  name: 'CourseSchedules',
  components: {
    'class-new-button': ClassNewDialogButton,
  },
  data() {
    return {
      ongoingSchedules: [],
    }
  },
  created() {
    this.$store.dispatch('courseschedules/allOngoingSchedules')
      .then((data) => { this.ongoingSchedules = data })
  },
  methods: {
    toSchedule(id) {
      this.$router.push(`/courseschedule/${id}`)
    },
    conductDeduction() {
      this.$store.dispatch('transactions/createByEnroll')
        .then(() => alert('done'))
    },
    addSchedule(schedule) {
      this.ongoingSchedules.push(schedule)
    },
  },
}
</script>

<style>

</style>
